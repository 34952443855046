<template>
    <div id="page-politicas">
        <Header />
        <div class="bloquepoliticas">
            <div class="filaunopol">
                <h1 class="titpol">Política de privacidad</h1>
            </div>
            <div class="filadospol">
                <p class="txtbold">La presente Política de Privacidad establece los términos en que Legrafica hará uso de la información proporcionada por los usuarios al momento de utilizar su sitio web; también detalla la forma en que protege el almacenamiento de dicha información.</p>
                <p>Cuando un usuario realiza un registro en este sitio web por primera vez, se le solicitará llenar los campos de información personal, esto se realiza con la finalidad de que puedan ser identificados en el futuro. </p>
                <p>Los datos personales sólo se emplearán de acuerdo con los términos de este documento. Sin embargo es necesario aclarar que esta Política de Privacidad puede cambiar con el tiempo. Por ello es recomendable revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.</p>
                <p class="txtit">Solicitud de Información</p>
                <p>Nuestro sitio web recoge información personal necesaria para el correcto registro. Por ejemplo: Nombre,  información de contacto como dirección de correo electrónico e información demográfica. De ser necesario, también podrá requerir información específica para procesar algún pago, realizar una entrega o facturación.</p>
                <p class="txtit">Uso de la información</p>
                <p>La información almacenada se emplea con el fin de proporcionar el mejor servicio posible. Se requiere para mejorar la experiencia de usuarios, mantener un registro de visitantes, de compras en caso que aplique, entrega de facturas, difusión de novedades y promociones, entre otros servicios.</p>
                <p>Es posible que se envíen correos electrónicos periódicamente a través de nuestro sitio con ofertas especiales, nuevos servicios y otra información publicitaria que consideremos relevante para usted o que pueda brindarle algún beneficio. Estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.</p>
                <p>Legrafica está altamente comprometido con mantener su información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.</p>
                <p class="txtit">Cookies</p>
                <p>Una cookie es un fichero que se envía con la finalidad de solicitar permiso para almacenarse en tu ordenador, al aceptar dicho fichero, la cookie se crea automáticamente. Sirve para tener información respecto al tráfico web y facilitar las futuras visitas a una web recurrente. Con ellas, la web puede reconocer tu identidad y por tanto brindarte el mejor servicio personalizado. Por ejemplo, si necesitas acceder a tu perfil o realizar un segundo registro, tus datos se completarán de manera automática.</p>
                <p>Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información se emplea únicamente para análisis estadístico y después la información se elimina de forma permanente.</p>
                <p>Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo las cookies ayudan a proporcionar un mejor servicio de los sitios web, <span>estas no dan acceso a información de su ordenador ni de usted, a menos de que usted así lo quiera y la proporcione directamente.</span></p>
                <p>Usted puede aceptar o negar el uso de cookies, sin embargo la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. También usted puede cambiar la configuración de su ordenador para declinar las cookies. Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.</p>
                <p class="txtit">Enlaces a Terceros</p>
                <p>Este sitio web puede contener enlaces a otros sitios que pueden ser de su interés. Una vez que usted da clic en estos enlaces y abandona nuestra página, nosotros ya no tenemos ningún control sobre el sitio al que es redirigido. Por lo tanto no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. 
Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.</p>
                <p class="txtit">Control de la información personal</p>
                <p>En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestro sitio web.  Cada vez que se le solicite rellenar un formulario, como el de alta de usuario, puede marcar o desmarcar la opción de recibir información por correo electrónico.  En caso de que haya marcado la opción de recibir nuestro boletín o publicidad usted puede cancelarla en cualquier momento.</p>
                <p>Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con una orden judicial.</p>
                <p>La empresa Legrafica se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.</p>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "../components/on-site/Header.vue";
import Footer from "../components/on-site/Footer.vue"

export default {
    name: 'About',
    components:{
        Header,
        Footer
    }
}
</script>

<style scoped>
    #page-politicas {
        background-color: #E4E4F9;
    }
    .filaunopol {
        background-image: url(../assets/fondopoliticas.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-top: 16.5625VW;
        padding-left: 9.895833333333332VW;
        padding-bottom: 16.458333333333332VW;
    }          
    h1.titpol {
        margin: 0;
        color: #EF67C2;
        font-family: Gramatika-Medium;
        font-size: 7.8125VW;
        width: 50%;
        line-height: 9.4765625VW;
    }
    .filadospol {
    width: 70%;
    margin: auto;
    text-align: justify;
    }

    .filadospol p:first-child {
        font-size: 1.0416666666666665VW;
        font-weight: 600;
        font-family: "IBM Plex Mono";
        line-height: 1.8729166666666668VW;
        margin-bottom: 3vw;
    }
    .filadospol p {
        font-family: "IBM Plex Mono";
        font-size: 0.8333333333333334VW;
        line-height: 1.4984374999999999VW;
        font-weight: 400;
        margin: 0;
        margin-bottom: 1vw;
    }
    .filadospol .txtit {
        font-weight: 600;
        margin-top: 2vw;
        margin-bottom: 1vw;
    }
    .filadospol p:last-child {
        margin-bottom: 15vw;
    }

    @media (max-width: 768px) {
        .anchor {
            position: absolute;
            top: 6.916256vh;
            left: 0;
            margin-left: 0;
            width: 35vw;
        }
        .filaunopol {
            background-image: none;
            padding-top: 50vw;
            padding-left: 0vw;
            width: 70%;
            margin: auto;
        }
        h1.titpol {
            margin: 0;
            font-size: 14VW;
            width: 100%;
            line-height: 17.57971014492754VW;
            font-weight: 400;
        }
        .filadospol p:first-child {
            font-size: 2.1739130434782608VW;
            font-weight: 600;
            font-family: "IBM Plex Mono";
            line-height: 3.908212560386473VW;
            margin-bottom: 8vw;
        }
        .filadospol .txtit {
            font-size: 2.1739130434782608VW;
            font-weight: 600;
            font-family: "IBM Plex Mono";
            line-height: 3.908212560386473VW;
            margin-bottom: 5vw;
        }
        .filadospol p {
            font-family: "IBM Plex Mono";
            font-size: 2.1739130434782608VW;
            line-height: 3.908212560386473VW;
            font-weight: 400;
            margin: 0;
            margin-bottom: 3vw;
        }
        .filadospol .txtit {
            margin-top: 6vw;
        }
        .filadospol p:last-child {
            margin-bottom: 30vw;
        }
    }
    


</style>